import request, { get, post, put, del } from '@/utils/request'

export default {
  getList(params = {}) {
    return request.get('/rest/user', params)
  },

  addUser(data) {
    return post(`/rest/user`, data)
  },

  updateUser(user_id, data) {
    return put(`/rest/user/${user_id}`, data)
  },

  del(user_id) {
    return del(`/rest/user/${user_id}`)
  },
  role() {
    return get('/rest/user/role')
  },
}
