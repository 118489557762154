<template>
  <div class="app-container user-container">
    <el-form :inline="true" :model="listQuery" ref="queryForm">
      <el-form-item label="用户名：">
        <el-input v-model="listQuery.user_name" placeholder="输入登录用户名"></el-input>
      </el-form-item>
      <el-form-item label="昵称：">
        <el-input v-model="listQuery.nickname" placeholder="输入账号昵称"></el-input>
      </el-form-item>
      <el-form-item label="账号角色">
        <el-select v-model="listQuery.role" placeholder="选择角色">
          <el-option value="">请选择</el-option>
          <el-option v-for="role in roles" :label="role.name" :value="role.role" :key="role.role"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit">搜索</el-button>
        <el-button type="default" @click="onReset">重置</el-button>
        <el-divider direction="vertical"></el-divider>
        <el-button type="success" @click="showAdd" icon="el-icon-plus">添加账号</el-button>
      </el-form-item>
    </el-form>

    <el-table
      :data="list"
      v-loading="loading"
      border
      fit
      hightlight-current-row
    >
      <el-table-column :resizable='false' label="账号" width="200" align="center" prop="user_name" fixed />
      <el-table-column :resizable='false' label="昵称" min-width="250" align="center" prop="nickname" />
      <el-table-column :resizable='false' label="角色" width="250" align="center" prop="role">
        <template slot-scope="{ row }">
          <span>{{ roleMap[row.role] }}</span>
        </template>
      </el-table-column>
      <el-table-column :resizable='false' label="添加时间" width="250" align="center" prop="created_time">
        <template slot-scope="scope">
          <span>{{$parseTime(scope.row.created_time, '{y}-{m}-{d} {h}:{i}:{s}')}}</span>
        </template>
      </el-table-column>
      <el-table-column :resizable='false' label="操作" width="180" align="center">
        <template slot-scope="scope">
          <el-link type="primary" icon="el-icon-edit" @click="doEdit(scope.row)" :disabled="!isAdmin">编辑</el-link>
          <el-link type="danger" icon="el-icon-delete" :disabled="user.user_id === scope.row.uuid || !isAdmin"  @click="doDelete(scope.row)">删除</el-link>
        </template>
      </el-table-column>
    </el-table>

      <!-- 分页 -->
      <el-pagination
        :current-page="listQuery.page"
        :page-size="listQuery.page_size"
        :total="total"
        style="padding: 32px 0;"
        layout="total, prev, pager, next, jumper"
        @current-change="fetchData"/>

    <el-dialog width="40%" :title="postData.uuid ? '编辑账号' : '添加账号'" :visible.sync="dialogVisible" @closed="hideDialog">
      <el-form :rules="rules" ref="postForm" :model="postData" label-width="100px" style="width:450px">
        <el-form-item label="账号" prop="user_name">
          <el-input v-model="postData.user_name" autocomplete="off" name="user_name" placeholder="输入账号"></el-input>
        </el-form-item>
        <el-form-item label="昵称" prop="nickname">
          <el-input v-model="postData.nickname" autocomplete="off" name="nickname" placeholder="输入昵称"></el-input>
        </el-form-item>
        <el-form-item label="初始密码" prop="password">
          <el-input v-model="postData.password" name="password" autocomplete="off" :type="type" placeholder="******"></el-input>
          <span class="show-pwd" @click="changeType">
            <svg-icon icon-class="eye" />
          </span>
        </el-form-item>
        <el-form-item label="账号角色" prop="role">
          <el-select v-model="postData.role" placeholder="选择角色" :disabled="user.user_id === postData.uuid">
            <el-option value="">请选择</el-option>
            <el-option v-for="role in roles" :label="role.name" :value="role.role" :key="role.role"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所属使馆" prop="diplomatic" v-if="postData.role === 'diplomatic'">
          <el-select v-model="postData.diplomatic" placeholder="选择所属使馆">
            <el-option value="">请选择</el-option>
            <el-option v-for="item in dipmList" :label="item.name" :value="item.id" :key="item.id"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="hideDialog">取 消</el-button>
        <el-button type="primary" @click="doSure" :loading="uploading">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import user from '@/api/user'
import dipm from '@/api/dipm'
import { mapState, mapGetters } from 'vuex'

const validPassword = (rule, value, callback) => {
  if (!value) {
    callback();
    return;
  }
  if (value.length < 6) {
    callback(new Error('请输入最低6位的密码'))
  }
  else {
    callback()
  }
}
const listQuery = {
  page_size: 20,
  page: 1,
}
export default {
  data() {
    return {
      type: 'password',
      departments: [],
      listQuery: {
        ...listQuery
      },
      total: 0,
      list: [],
      loading: false,
      roles: [],
      roleMap: {},
      dipmList: [],

      dialogVisible: false,
      uploading: false,
      postData: {
        user_name: ''
      },

      rules: {
        user_name: [{ required: true, max: 32, message: '请输入正确的账号信息' }],
        nickname: [{ required: true, max: 32, message: '请输入正确的昵称' }],
        role: [{ required: true, message: '请选择角色' }],
        diplomatic: [
          { required: true, message: '请选择所属使馆' }
        ]
      }
    }
  },
  computed: {
    ...mapGetters(['isAdmin']),
    ...mapState(['user']),
  },
  created() {
    this.fetchData();
    this.getRoles()
    this.getDipm()
  },
  methods: {
    getDipm() {
      dipm.getList({ page_size: 999 })
      .then(res => {
        this.dipmList = res.list || []
      })
    },
    getRoles() {
      user.role()
      .then(res => {
        this.roleMap = res || {}
        this.roles = Object.keys(res || {}).map(v => {
          return { role: v, name: res[v] }
        })
      })
    },
    showAdd() {
      this.rules.password = [
        { required: true, min: 8, max: 32,  message: '必须包含数字、大小写字母、特殊符号，长度8-32个字符' },
        //{ pattern: /(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[`~!@#$%^&*()_+<>?:"{},.\/;'[\]])^.{8,32}$/, message: '必须同时包含数字、大小写字母、特殊符号', trigger: [ 'blur' ] }
      ]
      this.postData = {}
      this.dialogVisible = true
      this.$nextTick(() => {
        this.$refs.postForm.clearValidate();
      })
    },
    onSubmit() {
      this.fetchData(1)
    },
    onReset() {
      this.listQuery = { ...listQuery }
      this.fetchData(1)
    },
    fetchData(page = 1) {
      this.listQuery.page = page
      this.loading = true
      user.getList(this.listQuery)
      .then(res => {
        this.total = +res.total
        this.list = res.list || []
        this.loading = false
      })
    },
    doDelete(record) {
      this.$confirm('您确定要删除该用户吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
      .then(() => {
        user.del(record.uuid)
        .then(() => {
          this.$message.success('删除成功')
          this.list = this.list.filter(v => v.uuid !== record.uuid)
          this.total -= 1
        })
        .catch((res) => {
          this.$message.error(res.error_msg || '删除失败')
        })
      })
    },
    hideDialog() {
      this.postData = {}
      this.type = 'password'
      this.dialogVisible = false
    },
    changeType() {
      this.type = this.type === 'password' ? 'text' : 'password'
    },
    doEdit(record) {
      this.rules.password = [
        { validator: validPassword, trigger: 'blur' }
      ]
      this.postData = { ...record }
      this.dialogVisible = true
      this.uploading = false
      this.$nextTick(() => {
        this.$refs.postForm.clearValidate();
      })
    },
    doSure() {
      this.$refs.postForm.validate(valid => {
        if (!valid) return false
        const { user_name, nickname, role, password = '', uuid, diplomatic = 0 } = this.postData
        const data = { user_name, role, password, nickname, diplomatic }
        this.uploading = true
        if (uuid > 0) {
          user.updateUser(uuid, data)
          .then((user) => {
            this.$message.success('修改成功')
            this.list = this.list.map(v => {
              if (v.uuid === uuid) {
                v = { ...v, ...data }
              }
              return v
            })
            this.dialogVisible = false
            this.uploading = false 
          })
          .catch((res) => {
            this.uploading = false 
          })
        }
        // 新增
        else {
          user.addUser(data)
          .then(uuid => {
            this.uploading = false 
            this.$message.success('添加成功')
            this.list = [ ...this.list, { ...data, uuid, created_time: new Date() } ]
            this.dialogVisible = false
            this.total += 1
          })
          .catch((res) => {
            this.uploading = false
          })
        }
      })
    }
  }
}
</script>

<style lang='less' scoped>
@dark_gray:#889aa4;
  .show-pwd {
    position: absolute;
    right: 10px;
    top: 0;
    font-size: 16px;
    color: @dark_gray;
    cursor: pointer;
    user-select: none;
  }
</style>
